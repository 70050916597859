import "./404.css";

const notFound = () => {
  return (
    <div id="main">
      <div class="fof">
        <h1>Error 404</h1>
      </div>
    </div>
  );
};

export default notFound;
